<template>
  <div id="organisation-invoices">
    <b-row class="mt-3">
      <b-col cols="9" sm="7" md="5" lg="4" xl="3">
        <b-form-group label-cols="3" label="Sort by:" label-for="cboSort">
          <b-form-select v-model="sort" :options="sortOptions" @change="sortChanged" id="cboSort" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col md="6">
        <b-button class="mr-1" @click.stop="showDeleteSelectedModal()" :disabled="!anyChecked">
          <font-awesome-icon class="mr-1" :icon="['fas', 'trash']" />Delete Selected
        </b-button>
        <b-button variant="danger" class="mr-1" @click.stop="showDeleteAllModal()">
          <font-awesome-icon class="mr-1" :icon="['fas', 'trash']" />Delete All
        </b-button>
        <!-- <b-button
          variant="outline-secondary"
          @click.stop="$emit('refresh')"
        >
          <font-awesome-icon icon="sync" />
        </b-button>-->
      </b-col>
      <b-col md="6">
        <div class="text-right my-1" v-if="this.invoices.length > 0">
          <span>Table rows:</span>
          <span class="ml-1">
            <span v-if="perPage == 20">20</span>
            <b-link v-else @click.stop="setPageSize(20)">20</b-link>,
            <span v-if="perPage == 50">50</span>
            <b-link v-else @click.stop="setPageSize(50)">50</b-link>,
            <span v-if="perPage == 100">100</span>
            <b-link v-else @click.stop="setPageSize(100)">100</b-link>,
            <span v-if="perPage == 200">200</span>
            <b-link v-else @click.stop="setPageSize(200)">200</b-link>,
            <span v-if="perPage == 500">500</span>
            <b-link v-else @click.stop="setPageSize(500)">500</b-link>,
            <span v-if="perPage == allItems">All</span>
            <b-link v-else @click.stop="setPageSize(allItems)">All</b-link>
          </span>
        </div>
        <b-pagination
          v-if="this.invoices.length > 0"
          align="right"
          :total-rows="totalRows"
          :per-page="perPage"
          v-model="currentPage"
          class="my-0"
          :first-text="'\u00AB'"
          :prev-text="'\u2039'"
          :next-text="'\u203A'"
          :last-text="'\u00BB'"
          :ellipsis-text="'\u2026'"
        />
      </b-col>
    </b-row>

    <b-table
      v-bind:empty-text="emptytext"
      show-empty
      responsive
      :items="invoices"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :busy.sync="isBusy"
      @row-dblclicked="onClick"
    >
      <template v-slot:table-busy>
        <Busy primary text="Loading..." />
      </template>
      <template v-slot:cell(selected)="row">
        <b-form-checkbox
          @click.native.stop
          @change="checkInvoice(row.item, row.index, $event)"
          :v-model="checkedContains(row.item)"
        ></b-form-checkbox>
      </template>
      <template v-slot:cell(isInSpotlight)="row">
        <SpotlightIcon :sector="row.value" />
      </template>
      <template v-slot:cell(subAmount)="row">{{ row.value | currency }}</template>
      <template v-slot:cell(taxAmount)="row">{{ row.value | currency }}</template>

      <template v-slot:cell(HEAD_attachments)="data">
        <font-awesome-icon :icon="['fas', 'paperclip']" :title="data.label" />
      </template>

      <template v-slot:cell(newInvoice)="row">
        <b-badge class="newBadge" v-if="row.value">New</b-badge>
      </template>

      <template v-slot:cell(attachments)="row">
        <AttachmentButton :row="row" />
      </template>

      <template v-slot:cell(createdDate)="row">
        {{ row.value | date }}
      </template>

      <template v-slot:cell(createdInReducer)="row">
        {{ row.value | date }}
        <div class="reducer-badge">
          <b-badge v-if="row.item.manualUpload" variant="primary" class="p-1 ml-1"
            >Manual Upload</b-badge
          >
        </div>
      </template>

      <template v-slot:cell(actions)="row">
        <b-button size="sm" @click.stop="row.toggleDetails">
          Lines
          <b-badge
            variant="primary"
            v-if="row.item.invoiceLineItems && row.item.invoiceLineItems.length > 1"
            >{{ row.item.invoiceLineItems.length }}</b-badge
          >
        </b-button>
      </template>
      <template v-slot:row-details="row">
        <ul>
          <li v-for="(value, key) in row.item.invoiceLineItems" :key="key">
            <span v-for="(ivalue, ikey) in value" :key="ikey">
              <span
                v-if="
                  ikey != 'invoiceLineItemId' &&
                  ikey != 'organisationId' &&
                  ikey != 'invoiceId' &&
                  ivalue != null
                "
              >
                <strong>{{ ikey }}:</strong>
                {{ ivalue }}
              </span>
            </span>
          </li>
        </ul>
      </template>

      <!-- Editable fields -->
      <template v-slot:cell(customerName)="row">
        <b-form-input
          v-if="canEdit(row)"
          class="tableInput"
          :disabled="rowBusy"
          v-model="rowClicked.customerName"
        />
        <div v-else>{{ row.value }}</div>
      </template>

      <template v-slot:cell(amount)="row">
        <b-form-input
          v-if="canEdit(row)"
          class="tableInput"
          :disabled="rowBusy"
          v-model="rowClicked.amount"
        />
        <div v-else>{{ row.value | currency }}</div>
      </template>

      <template v-slot:cell(dueDate)="row">
        <b-form-input
          v-if="canEdit(row)"
          class="tableInput"
          type="date"
          :disabled="rowBusy"
          v-model="rowClicked.dueDate"
        />
        <div v-else>{{ row.value | date }}</div>
      </template>

      <template v-slot:cell(invoiceReference)="row">
        <b-form-input
          v-if="canEdit(row)"
          class="tableInput"
          :disabled="rowBusy"
          v-model="rowClicked.invoiceReference"
        />
        <div v-else>{{ max150(row.value) }}</div>
      </template>
    </b-table>
    <b-row>
      <b-col md="6"></b-col>
      <b-col md="6">
        <b-pagination
          v-if="this.invoices.length > 0"
          align="right"
          :total-rows="totalRows"
          :per-page="perPage"
          v-model="currentPage"
          class="m-1"
          :first-text="'\u00AB'"
          :prev-text="'\u2039'"
          :next-text="'\u203A'"
          :last-text="'\u00BB'"
          :ellipsis-text="'\u2026'"
        />
      </b-col>
    </b-row>
    <b-modal
      id="deleteAllModal"
      ref="deleteAllModal"
      title="Are you sure you want to delete all of the invoices for this organisation?"
      @ok="handleDeleteAll"
    >
      <p>
        This will delete all accounting data in Reducer for this organisation, even if there are
        other users of the organisation. The organisation will remain active.
      </p>
    </b-modal>
    <b-modal
      id="deleteSelectedModal"
      ref="deleteSelectedModal"
      title="Are you sure you want to delete the selected invoices?"
      @ok="handleDeleteSelected"
    >
      <p>
        This will delete the
        <b-badge variant="primary">{{ checked.length }}</b-badge
        >selected invoices from Reducer.
      </p>
    </b-modal>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
#organisation-invoices {
  .tableInput {
    color: $color-font-headings;
    text-align: center;
  }
  .table-edit {
    background-color: $color-grey-lighter5 !important;
  }
  .newBadge {
    background-color: $color-pink-main;
  }
}
</style>

<script>
import SpotlightIcon from "@/components/spotlight/SpotlightIcon";
import AttachmentButton from "@/components/AttachmentButton";
import ApiHelper from "@/helper/apihelper";
import Busy from "@/components/Busy";
import FormatHelper from "@/helper/formathelper";

export default {
  name: "OrganisationInvoices",
  props: {
    invoices: Array,
    isBusy: Boolean,
    organisation: Object,
    totalRows: Number,
    allItems: Number,
    emptytext: String,
    fields: Array,
    isAdmin: Boolean,
    isUpdatingInvoice: Boolean
  },
  components: { AttachmentButton, Busy, SpotlightIcon },
  computed: {
    anyChecked: function () {
      return this.checked.length > 0;
    },
    canEdit: function () {
      return (row) => this.rowClicked && this.rowClicked.invoiceId === row.item.invoiceId;
    }
  },
  watch: {
    currentPage: function (value) {
      this.$emit("current-page", value);
    },
    checked: function () {
      this.$forceUpdate();
    },
    rowClicked: function (newValue, oldValue) {
      if (oldValue) {
        // remove color from previously selected row
        this.findInvoice(oldValue.invoiceId)._rowVariant = null;
      }
      if (newValue) {
        // color selected row
        this.findInvoice(newValue.invoiceId)._rowVariant = "edit";
        // format date on selected row for form input
        this.rowClicked.dueDate = this.formatDate(newValue.dueDate);
      }
    }
  },
  created() {
    window.addEventListener("keyup", (event) => {
      // on enter, submit selected invoice data
      if (event.keyCode === 13 && this.rowClicked) {
        this.updateInvoice(this.rowClicked);
        this.rowClicked = null;
      }
      // on esc, unselect row
      else if (event.keyCode === 27) {
        this.rowClicked = null;
      }
      // on arrow up, select row above
      else if (event.keyCode === 38) {
        this.selectRow(-1);
      }
      // on arrow down, select row below
      else if (event.keyCode === 40) {
        this.selectRow(1);
      }
    });
  },
  data() {
    return {
      currentPage: 1,
      perPage: ApiHelper.DEFAULT_INVOICE_COUNT,
      checked: [],
      rowClicked: null,
      rowBusy: null,
      sort: null,
      sortOptions: [
        { value: null, text: "Choose how to sort", disabled: true },
        { value: "createdinreducer-descending", text: "Added to Reducer (new - old)" },
        { value: "createdinreducer-ascending", text: "Added to Reducer (old - new)" },
        { value: "name-descending", text: "Name (z-a)" },
        { value: "name-ascending", text: "Name (a-z)" },
        { value: "amount-descending", text: "Amount (large - small)" },
        { value: "amount-ascending", text: "Amount (small - large)" },
        { value: "issued-descending", text: "Issued (new - old)" },
        { value: "issued-ascending", text: "Issued (old - new)" }
      ]
    };
  },
  methods: {
    formatDate: (val) => FormatHelper.formatDateTimeCustom(val, "YYYY-MM-DD"),
    max150: FormatHelper.max150,
    setPageSize(size) {
      this.currentPage = 1;
      this.perPage = size;
      this.$emit("page-size", size);
    },
    removeChecked() {
      this.checked = [];
      this.$forceUpdate();
    },
    showDeleteAllModal() {
      this.$refs.deleteAllModal.show();
    },
    showDeleteSelectedModal() {
      this.$refs.deleteSelectedModal.show();
    },
    handleDeleteAll() {
      this.$emit("remove-all-invoices");

      this.checked = [];
    },
    handleDeleteSelected() {
      this.$emit("remove-selected-invoices", this.checked);

      this.checked = [];
    },
    checkInvoice(item, index, value) {
      var i = this.checked.indexOf(item);
      if (i > -1 && !value) {
        this.checked.splice(i, 1);
      } else if (i < 0 && value) {
        this.checked.push(item);
      }
    },
    checkedContains(item) {
      return this.checked.length > 0 && this.checked.indexOf(item) >= 0;
    },
    sortChanged(value) {
      const parts = value.split("-");
      const by = parts[0];
      const direction = parts[1];
      this.$emit("sort-changed", { sortBy: by, sortDirection: direction });
    },
    findInvoice(id) {
      let i = this.invoices.findIndex((i) => i.invoiceId === id);
      return this.invoices[i];
    },
    selectRow(index) {
      if (this.rowClicked) {
        let i = this.invoices.findIndex((i) => i.invoiceId === this.rowClicked.invoiceId);
        this.onClick(this.invoices[i + index]);
      } else {
        this.onClick(this.invoices[0]);
      }
    },
    async onClick(row) {
      if (this.isAdmin) {
        if (!this.rowClicked) {
          this.rowClicked = Object.assign({}, row);
          // clicking on a different row
        } else if (this.rowClicked.invoiceId !== row.invoiceId) {
          // submit data if there was a row selected
          this.updateInvoice(this.rowClicked);
          // save reference
          this.rowClicked = Object.assign({}, row);
        } else {
          // clicking on the same row
          // todo maybe unselect?
        }
      }
    },
    updateInvoice(invoice) {
      let invoiceToUpdate = this.findInvoice(this.rowClicked.invoiceId);

      // update if any values have been changed
      if (
        invoiceToUpdate.amount !== invoice.amount ||
        invoiceToUpdate.customerName !== invoice.customerName ||
        this.formatDate(invoiceToUpdate.dueDate) !== invoice.dueDate ||
        invoiceToUpdate.invoiceReference !== invoice.invoiceReference
      ) {
        Object.assign(invoiceToUpdate, invoice);
        if (invoice.dueDate) {
          invoice.dueDate += "T00:00:00.100Z";
        }
        this.$emit("update-invoice", invoice);
      }
    }
  }
};
</script>



<template>
  <font-awesome-icon
    v-b-popover.hover="sector"
    class="spotlightIcon"
    v-if="sector == 'Telecoms'"
    :icon="['fas', 'phone-office']"
  />
  <font-awesome-icon
    v-b-popover.hover="sector"
    class="spotlightIcon"
    v-else-if="sector == 'Waste'"
    :icon="['fas', 'dumpster']"
  />
  <font-awesome-icon
    v-b-popover.hover="sector"
    class="spotlightIcon"
    v-else-if="sector == 'Fuel'"
    :icon="['fas', 'gas-pump']"
  />
  <font-awesome-icon
    v-b-popover.hover="sector"
    class="spotlightIcon"
    v-else-if="sector == 'Energy'"
    :icon="['fas', 'lightbulb-on']"
  />
  <font-awesome-icon
    v-b-popover.hover="sector"
    class="spotlightIcon"
    v-else-if="sector == 'Water'"
    :icon="['fas', 'tint']"
  />
  <font-awesome-icon
    v-b-popover.hover="sector"
    class="spotlightIcon"
    v-else-if="sector == 'Card Processing'"
    :icon="['fas', 'credit-card-front']"
  />
  <font-awesome-icon
    v-b-popover.hover="sector"
    class="spotlightIcon"
    v-else-if="sector"
    :icon="['fas', 'hand-spock']"
  />
</template>

<script>
export default {
  name: "SpotlightIcon",
  props: {
    sector: String
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
.spotlightIcon {
  color: $color-pink-main;
}
</style>

<template>
  <div id="organisation-data">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <b-container fluid>
      <h1>Organisation Data</h1>
      <h4>{{ organisation.name }}</h4>
      <div>
        <b-form inline novalidate v-on:submit.prevent="search">
          <label class="mr-2 mb-2" for="query">Query:</label>
          <b-input class="mb-2" id="query" placeholder="e.g. British Gas" v-model="query" />
          <b-form-invalid-feedback class="inline-error">Query is not valid</b-form-invalid-feedback>

          <label class="mx-2 mb-2" for="minAmount">Min Amount:</label>
          <b-input
            class="mb-2"
            id="minAmount"
            placeholder="e.g. 100"
            v-model="minAmount"
            :state="isValidMinAmount"
          />
          <b-form-invalid-feedback class="inline-error"
            >Amount has to be a number</b-form-invalid-feedback
          >

          <label class="mx-2 mb-2" for="maxAmount">Max Amount:</label>
          <b-input
            class="mb-2"
            id="maxAmount"
            placeholder="e.g. 100"
            v-model="maxAmount"
            :state="isValidMaxAmount"
          />
          <b-form-invalid-feedback class="inline-error"
            >Amount has to be a number</b-form-invalid-feedback
          >
          <div class="mb-2 ml-2">
            <b-button
              type="
              submit"
              variant="primary"
              >Update</b-button
            >

            <b-button variant="secondary" @click.stop="clearSearch" class="ml-1">Clear</b-button>
          </div>
        </b-form>
      </div>

      <OrganisationInvoices
        :emptytext="emptytext"
        :invoices="invoices"
        @refresh="refreshData"
        @page-size="pageSize"
        @current-page="currentPage"
        :isBusy="fetchingInvoices > 0 || !gotSpotlightData || settingInvoices"
        :organisation="organisation"
        :totalRows="pager.totalCount"
        :allItems="allItems"
        :fields="fields"
        :isAdmin="isAdmin"
        :isUpdatingInvoice="isUpdatingInvoice"
        @remove-all-invoices="removeAllInvoices"
        @remove-selected-invoices="removeSelectedInvoices"
        @sort-changed="sortChanged"
        @update-invoice="updateInvoice"
      />
    </b-container>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";

.inline-error {
  position: absolute;
}
</style>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import OrganisationInvoices from "@/components/OrganisationInvoices";
import Console from "@/console";
import FormatHelper from "@/helper/formathelper";
import CognitoAuth from "@/cognito/cognitoauth";

export default {
  name: "OrganisationData",
  components: { OrganisationInvoices },
  data() {
    return {
      title: "Organisation Data",
      fields: [
        { key: "selected", label: "", class: "text-center align-middle" },
        {
          key: "newInvoice",
          label: "New",
          class: "text-center align-middle"
        },
        {
          key: "attachments",
          label: "Attachments",
          class: "text-center align-middle"
        },
        {
          key: "isInSpotlight",
          label: "",
          class: "text-center align-middle"
        },
        {
          key: "customerName",
          label: "Name",
          class: "text-center align-middle"
        },
        {
          key: "subAmount",
          label: "Amount exc tax",
          class: "text-center align-middle"
        },
        { key: "taxAmount", label: "Tax", class: "text-center align-middle" },
        {
          key: "amount",
          label: "Amount inc tax",
          class: "text-center align-middle"
        },
        {
          key: "currencyCode",
          label: "Currency",
          class: "text-center align-middle"
        },
        {
          key: "type",
          label: "Type",
          class: "text-center align-middle",
          formatter: (value) => {
            return this.formatInvoiceType(value);
          }
        },
        {
          key: "createdDate",
          label: "Issued",
          class: "text-center align-middle"
        },
        {
          key: "dueDate",
          label: "Due Date",
          class: "text-center align-middle"
        },
        {
          key: "createdInReducer",
          label: "Added to Reducer",
          class: "text-center align-middle"
        },
        {
          key: "invoiceReference",
          label: "Invoice Number",
          class: "text-center align-middle"
        },
        {
          key: "lineItemDescriptions",
          label: "Details",
          class: "text-center align-middle"
        },
        { key: "actions", label: "", class: "text-center align-middle" }
      ],
      organisation: {},
      invoices: [],
      tempInvoices: [],
      spotlightIds: [],
      fetchingInvoices: 0,
      gotSpotlightData: false,
      settingInvoices: false,
      pager: {
        start: 0,
        count: ApiHelper.DEFAULT_INVOICE_COUNT,
        totalCount: null,
        sortBy: "name",
        sortDirection: "ascending"
      },
      allItems: ApiHelper.ALL_ITEMS,
      emptytext: "",
      query: null,
      minAmount: null,
      maxAmount: null,
      isValidMinAmount: null,
      isValidMaxAmount: null,
      isAdmin: false,
      isUpdatingInvoice: false
    };
  },
  created() {
    this.getSpotlightData();
    this.fetchData();
  },
  watch: {
    $route: "fetchData",
    minAmount: function (value) {
      if (!value) {
        this.isValidMinAmount = null;
      }
    },
    maxAmount: function (value) {
      if (!value) {
        this.isValidMaxAmount = null;
      }
    }
  },
  methods: {
    formatInvoiceType: FormatHelper.formatInvoiceType,
    pageSize(size) {
      this.pager.count = size;
      this.refreshData();
    },
    currentPage(page) {
      this.pager.start = this.pager.count * (page - 1);
      this.refreshData();
    },
    search() {
      if (this.isValidSearch()) {
        this.pager.start = 0;
        this.tempInvoices = [];
        this.refreshData();
      }
    },
    clearSearch() {
      this.query = "";
      this.minAmount = "";
      this.maxAmount = "";
      this.search();
    },
    isValidSearch() {
      var isValid = true;
      this.isValidMinAmount = null;
      this.isValidMaxAmount = null;

      if (this.minAmount && this.minAmount != "") {
        const isMinValid = (this.isValidMinAmount = this.isNumber(this.minAmount));

        isValid = isValid && isMinValid;
      }

      if (this.maxAmount && this.maxAmount != "") {
        const isMaxValid = (this.isValidMaxAmount = this.isNumber(this.maxAmount));

        isValid = isValid && isMaxValid;
      }

      return isValid;
    },
    isNumber(value) {
      return !isNaN(value);
    },
    async getSpotlightData() {
      this.isAdmin = await CognitoAuth.isCurrentUserAdmin();
      var organisationId = this.$route.params.organisationId;
      try {
        const client = await ApiHelper.http();
        const response = await client.get(
          `${ApiHelper.endPoint()}invoices?organisationId=${organisationId}&includeSpotlight=true&includeNewFlag&itemsPerPage=${
            ApiHelper.ALL_ITEMS
          }`
        );

        Console.log(response);

        if (response.data.status === "success") {
          var localSpotlightIds = [];
          Console.log(response);
          if (response.data.spotlightGroups.length > 0) {
            response.data.spotlightGroups.forEach(function (group) {
              group.invoices.forEach(function (inv) {
                localSpotlightIds[inv.invoiceId] = group.name;
              });
            });
            this.spotlightIds = localSpotlightIds;
          }

          if (this.fetchingInvoices > 0) {
            Console.log("Finished getting Spotlight data before Invoice data");
          } else {
            Console.log("Finished getting Spotlight after Invoice data call returned");
            this.settingInvoices = true;
            this.setInvoices();
          }
        }
      } catch (err) {
        Console.log("Failed to process spotlight data");
        Console.log(err);
      } finally {
        this.gotSpotlightData = true;
      }
    },
    setInvoices() {
      try {
        Console.log("Setting invoices");
        Console.log(this.tempInvoices);
        var localSpotlightIds = this.spotlightIds;
        this.tempInvoices.forEach(function (invoice) {
          if (invoice.invoiceId in localSpotlightIds) {
            invoice.isInSpotlight = localSpotlightIds[invoice.invoiceId];
          }
        });
        this.invoices = this.tempInvoices;
      } finally {
        this.settingInvoices = false;
      }
    },
    async refreshData(organisationId) {
      if (organisationId == null) {
        organisationId = this.$route.params.organisationId;
      }
      this.fetchingInvoices++;
      try {
        let client = await ApiHelper.http();
        var response = await client.get(
          `${ApiHelper.endPoint()}organisations/${organisationId}/invoices${this.getInvoicesParams()}&includeNewFlag=true`
        );

        if (response.data.status === "success") {
          this.emptytext = "This organisation has no invoices";

          var grow = response.data.pager.next
            ? response.data.invoices.length
            : response.data.pager.totalCount;
          this.growInvoices(grow);
          this.addInvoices(this.pager.start, response.data.invoices);

          if (response.data.pager.totalCount) {
            this.pager.totalCount = response.data.pager.totalCount;
          } else {
            this.pager.totalCount = this.tempInvoices.length + grow;
          }

          if (this.gotSpotlightData) {
            Console.log("Finished getting Invoices and got Spotlight Data");
            this.settingInvoices = true;
            this.setInvoices();
          } else {
            Console.log("Finished getting Invoices and Spotlight call is still going");
          }
        } else {
          this.showWarning(
            (this.emptytext = "There was a problem getting invoices for this organisation")
          );
        }
      } catch {
        this.showWarning(
          (this.emptytext = "There was a problem getting invoices for this organisation")
        );
      } finally {
        this.fetchingInvoices--;
      }
    },
    getInvoicesParams() {
      var params = ApiHelper.appendParam(null, "offset", this.pager.start);
      params = ApiHelper.appendParam(params, "itemsPerPage", this.pager.count);
      params = ApiHelper.appendParam(params, "sortBy", this.pager.sortBy);
      params = ApiHelper.appendParam(params, "sortDirection", this.pager.sortDirection);
      params = ApiHelper.appendParam(params, "query", this.query);
      params = ApiHelper.appendParam(params, "minAmount", this.minAmount);
      params = ApiHelper.appendParam(params, "maxAmount", this.maxAmount);

      return params;
    },
    async fetchData() {
      var organisationId = this.$route.params.organisationId;

      try {
        let client = await ApiHelper.http();
        var response = await client.get(`${ApiHelper.endPoint()}organisations/${organisationId}`);

        Console.log(response.data);

        if (response.data.status === "success") {
          this.organisation = response.data.organisation;

          this.refreshData(organisationId);
        } else {
          this.showWarning("There was a problem getting the organisation.");
        }
      } catch {
        this.showWarning("There was a problem getting the organisation.");
      }
    },
    growInvoices(count) {
      if (count > this.tempInvoices.length) {
        for (var i = 0; i < count; i++) {
          this.tempInvoices.push({});
        }
      } else if (count < this.tempInvoices.length) {
        this.tempInvoices.slice(0, count);
      }
    },
    addInvoices(at, added) {
      for (var i = 0; i < added.length; i++) {
        this.$set(this.tempInvoices, i + at, added[i]);
      }
    },
    async removeAllInvoices() {
      try {
        let client = await ApiHelper.http();
        var response = await client.delete(
          `${ApiHelper.endPoint()}organisations/${this.organisation.organisationId}/data`
        );

        if (response.data.status === "success") {
          EventBus.$emit("show-toast", {
            message: "Request accepted: we are deleting the data in the background",
            variant: "success"
          });
          this.tempInvoices = [];
          this.invoices = [];
          this.pager.totalCount = 0;
        } else {
          this.showWarning("Sorry, there was a problem with that request. No data was deleted.");
        }
      } catch {
        this.showWarning("Sorry, there was a problem with that request. No data was deleted.");
      }
    },
    async removeSelectedInvoices(selected) {
      try {
        let client = await ApiHelper.http();
        var response = await client.delete(
          `${ApiHelper.endPoint()}organisations/${
            this.organisation.organisationId
          }/invoices?${this.invoiceIdParams(selected)}`
        );

        if (response.data.status === "success") {
          EventBus.$emit("show-toast", {
            message: "Request accepted: we are deleting the data in the background",
            variant: "success"
          });

          this.refreshData();
        } else {
          this.showWarning("Sorry, there was a problem with that request. No data was deleted.");
        }
      } catch {
        this.showWarning("Sorry, there was a problem with that request. No data was deleted.");
      }
    },
    async updateInvoice(invoice) {
      this.isUpdatingInvoice = true;
      try {
        let client = await ApiHelper.http();
        var response = await client.put(
          `${ApiHelper.endPoint()}organisations/${this.organisation.organisationId}/invoices`,
          { invoices: [invoice] }
        );

        if (response.data.status === "success") {
          EventBus.$emit("show-toast", {
            message: "Invoice updated successfully.",
            variant: "success"
          });
        } else {
          this.showWarning(
            "Sorry, there was a problem with that request. Invoice was not updated."
          );
          this.refreshData();
        }
      } catch (e) {
        Console.error(e);
        this.showWarning("Sorry, there was a problem with that request. Invoice was not updated.");
        this.refreshData();
      } finally {
        this.isUpdatingInvoice = false;
      }
    },
    invoiceIdParams(selected) {
      var params = "";
      selected.forEach(function (element) {
        if (params !== "") {
          params += "&";
        }
        params += "invoiceId=" + element.invoiceId;
      });

      return params;
    },
    showWarning(message) {
      EventBus.$emit("show-toast", { message: message, variant: "warning" });
    },
    sortChanged(sort) {
      this.pager.sortBy = sort.sortBy;
      this.pager.sortDirection = sort.sortDirection;
      this.refreshData();
    }
  }
};
</script>



<template>
  <div v-if="row.item.attachments">
    <b-dropdown v-if="row.item.attachments.length > 1" lazy>
      <template slot="button-content">
        <font-awesome-icon :icon="['fas', 'paperclip']" />
      </template>
      <b-dropdown-item
        v-for="attachment in row.item.attachments"
        :key="attachment.attachmentId"
        v-on:click="openAttachment(attachment)"
      >{{ attachment.fileName }}</b-dropdown-item>
    </b-dropdown>
    <b-button
      v-on:click="openAttachment(row.item.attachments[0])"
      v-else-if="row.item.attachments.length === 1"
      text="Attachment"
    >
      <font-awesome-icon :icon="['fas', 'paperclip']" />
    </b-button>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
</style>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";

export default {
  name: "AttachmentButton",
  props: {
    row: Object
  },
  data() {
    return {};
  },
  methods: {
    async openAttachment(attachment) {
      //open new tab before getting attachment, to avoid non-trusted event being blocked
      //assign the tab a unique idenitifier using the attachmentId
      window.open("about:blank", attachment.attachmentId);
      var organisationId = attachment.organisationId;
      if (!organisationId) {
        organisationId = this.$router.currentRoute.params.organisationId;
      }
      try {
        let client = await ApiHelper.http();
        var response = await client.get(
          `${ApiHelper.endPoint()}invoices/${
            attachment.invoiceId
          }?organisationId=${organisationId}&attachmentId=${attachment.attachmentId}`
        );

        if (response.data.status === "success") {
          window.open(response.data.signedUrl, attachment.attachmentId);
        } else {
          this.showWarning();
        }
      } catch {
        this.showWarning();
      }
    },
    showWarning() {
      EventBus.$emit("show-toast", {
        message: "Failed to get organisation attachment",
        variant: "warning"
      });
    }
  }
};
</script>